import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import LayoutWithSidebar from "../layout/withSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faDribbble,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import Seo from "../components/seo";
import "../css/contact.css";

const ContactPage = () => {
  const pageTitle = "Contact Me";
  const pageSubtitle =
    '"Louis, I think this is the beginning of a beautiful friendship." - Casablanca, 1942';

  return (
    <LayoutWithSidebar pageTitle={pageTitle} pageSubtitle={pageSubtitle}>
      <Seo title="Contact me" />
      <div className="row gy-3">
        <div className="col-12 col-xl-5">
          <div className="card">
            <div className="card-body">
              <form name="contact" method="POST" data-netlify="true">
                <div className="form-col mb-3">
                  <label htmlFor="submitterName" className="form-label">
                    Your name
                  </label>
                  <input
                    type="text"
                    name="submitter name"
                    className="form-control"
                    id="submitterName"
                    placeholder="e.g. John Doe"
                  />
                </div>
                <div className="form-col mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="submitter email"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="e.g. info@example.com"
                  />
                </div>
                <div className="form-col mb-4">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Your message
                  </label>
                  <textarea
                    className="form-control"
                    name="submitter email"
                    placeholder="Can't wait to hear from you"
                    id="exampleFormControlTextarea1"
                    rows="12"
                  ></textarea>
                </div>
                <button className="el-button full-w" type="submit">
                  <span className="el-button--label">Submit</span>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-7">
          <div className="row gy-3">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3 mb-xl-0">
                      <StaticImage
                        className="static-image contact-img"
                        src="../images/me-welcoming.jpg"
                        quality={95}
                        alt="Profile picture"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <h2 className="h2 mt-1">Let's talk about it</h2>
                      <p>
                        To check if I am free or simply to get more information,
                        just complete the contact form.
                      </p>
                      <p>Let's build something together, shall we?</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card social-card">
                <div className="card-body">
                  <FontAwesomeIcon
                    className="button--icon"
                    icon={faInstagram}
                  />
                  <span className="dropdown-item--title">Instagram</span>
                  <a
                    href="https://www.instagram.com/hessam_khoobkar/"
                    className="stretched-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card social-card">
                <div className="card-body">
                  <FontAwesomeIcon className="button--icon" icon={faDribbble} />
                  <span className="dropdown-item--title">Dribbble</span>
                  <a
                    href="https://dribbble.com/hessam_khoobkar"
                    className="stretched-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dribbble
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card social-card">
                <div className="card-body">
                  <FontAwesomeIcon className="button--icon" icon={faLinkedin} />
                  <span className="dropdown-item--title">LinkedIn</span>
                  <a
                    href="https://www.linkedin.com/in/hessam-khoobkar/"
                    className="stretched-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card social-card">
                <div className="card-body">
                  <FontAwesomeIcon className="button--icon" icon={faGithub} />
                  <span className="dropdown-item--title">Github</span>
                  <a
                    href="https://github.com/hessamkhoobkar"
                    className="stretched-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default ContactPage;
